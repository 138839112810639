import React from 'react';

const GoogleMaps = () => {
  return (
    <div id="mapa" style={{ position: 'relative', width: '100%', height: '100vh' }}>
      <iframe
        title="Google Maps"
        frameBorder="0"
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.723650688931!2d-46.64186118980534!3d-23.578366662127266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce59d86c5d7255%3A0x3a9ade104ba09fc9!2sDra%20Samia%20Villar%20Navajas!5e0!3m2!1spt-BR!2sbr!4v1688557845451!5m2!1spt-BR!2sbr"
        draggable={true}
      />
    </div>
  );
}

export default GoogleMaps;
