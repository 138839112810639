import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/retina.png";
import ShieldIconImage from "../../images/uivite.png";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-24 h-24`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Uveíte",
      description: (
        <>
          Uveíte é uma inflamação que acomete os olhos, mais especificamente o trato uveal (íris e estruturas ricas em vasos sanguíneos). Alguns sintomas da uveíte são vermelhidão, dor nos olhos, dificuldade para enxergar, sensibilidade à luz, visão com pontos pretos que se movimentam (moscas volantes), entre outros incômodos.
          <br /><br />
          O que nós tratamos?
          <br /><br />
          Nós não só tratamos os sintomas da uveíte, mas também investigamos suas causas, como doenças autoimunes (Doença de Behçet, Artrite Idiopática Juvenil, Espondilite Anquilosante, entre outras) e doenças infecciosas (Toxoplasmose, Sífilis, Herpes, entre outras).
        </>)
    },
    {
      imageSrc: SupportIconImage, title: "Retina", description: (
        <>
          A retina é uma importante estrutura dos olhos composta por milhares de células sensíveis à luz, com papel fundamental na formação das imagens.
          <br /><br />
          A retina é uma importante estrutura dos olhos composta por milhares de células sensíveis à luz, com papel fundamental na formação das imagens.
          <br /><br />
          Procure um especialista se houver perda de visão, manchas no campo visual, distorção de imagens (metamorfopsia), entre outros.
          <br /><br />
          Algumas comorbidades também podem causar alterações estruturais na retina. Cuide da sua retina se você tiver diabetes mellitus, hipertensão arterial, miopia e colesterol alto.
          <br /><br />
          Algumas comorbidades também podem causar alterações estruturais na retina. Cuide da sua retina se você tiver diabetes mellitus, hipertensão arterial, miopia e colesterol alto.
          <br /><br /><br />
          Os tratamentos podem ser feitos com medicações sistêmicas ou oculares com injeções intravítreas e laser
        </>
      )
    },
  ];

  return (
    <Container id="especialidades">
      <ThreeColumnContainer>
        <Heading><span tw="text-primary-500">Especialidades</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
