import React from "react";
import tw from "twin.macro";
import {Container as ContainerBase } from "components/misc/Layouts.js"

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;




const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
export default () => {
  return (
    <Container>
      <Content>
        <Row>
            <LogoText>Samia Villar Navajas</LogoText>
          <CopyrightText>
            &copy; {new Date().getFullYear()}
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
