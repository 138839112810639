import React from 'react';
import Background from 'images/salaespera2.jpg'; // Importe a imagem corretamente

const Agendamento = () => {
  return (
    <div style={{
      backgroundImage: `url(${Background})`, // Defina a imagem como plano de fundo
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '50vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '109vw',
      position: 'relative',
      left: '-9vw',
      marginLeft: '0%',
      overflow: 'hidden'
    }}>
      <a href="https://wa.me/5511966487889?text=Ol%C3%A1%21%21+Gostaria+de+agendar+consulta+com+Dra.+Samia"
        style={{
          '@media (min-width: 768px)': {
            marginLeft: '9vh', // Ajuste para dispositivos de largura mínima de 768px
          },
          '@media (max-width: 767px)': {
            marginLeft: '-9vh', // Ajuste para dispositivos de largura máxima de 767px
          }
        }}
      >
        <div style={{
          background: 'rgba(0, 64, 0, 0.5)', // Cor verde translúcido
          width: '300px',
          height: '200px',
          borderRadius: '7px',
          marginRight: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}>
          <h1 style={{ color: 'white', fontSize: '18px', fontWeight: 'bold', marginBottom: '20px' }}>
            Venha conhecer nosso espaço
          </h1>
          <button style={{
            background: 'white',
            marginTop: '20px',
            color: 'rgba(0, 128, 0, 0.5)',
            border: 'none',
            borderRadius: '7px',
            padding: '10px 20px',
            fontSize: '16px',
            fontWeight: 'bold',
            cursor: 'pointer',
          }}>
            Agende sua consulta!
          </button>
        </div>
      </a>
    </div>
  );
}

export default Agendamento;
