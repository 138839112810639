import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Slider from "react-slick";
import { SectionHeading, Subheading } from "components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import salaespera from "images/salaespera.jpg";
import salaespera2 from "images/salaespera2.jpg";
import salaescritorio from "images/salaescritorio.jpg";
import salaescritorio2 from "images/salaescritorio2.jpg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div`xl:mr-12 xl:last:mr-0`;
const HeadingColumn = styled(Column)(props => [
  tw`w-full xl:w-5/12`,
  props.textOnLeft ? tw`xl:order-first` : tw`xl:order-last xl:ml-12 xl:mr-0`
]);
const CardColumn = tw(Column)`w-full md:w-1/2 xl:w-3/12 mt-16 xl:mt-0 xl:last:ml-auto`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`mt-4 xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;
const PrimaryLink = styled(PrimaryLinkBase)`
    ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
    svg {
      ${tw`ml-2 w-5 h-5`}
    }
  `;

const Card = tw.div`mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
const CardImage = ({ imageSrc }) => (
  <div
    style={{
      backgroundImage: `url("${imageSrc}")`,
      backgroundSize: "100% 100%",
      height: "300px",
      backgroundPosition: "center",
      borderRadius: "4px",
    }}
  />
);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardCompany = tw.div`text-primary-500 font-bold text-lg`;
const CardType = tw.div`font-semibold text-sm text-gray-600`;

const CardTitle = tw.h5`text-xl mt-4 font-bold`;

const CardMeta = styled.div`
    ${tw`flex flex-row flex-wrap justify-center sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
  `;

const CardMetaFeature = styled.div`
    ${tw`flex items-center mt-4 mr-4 last:mr-0`}
    svg {
      ${tw`w-5 h-5 mr-1`}
    }
  `;

const sliderSettings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1
};

const CardAction = tw(PrimaryButtonBase)`w-full mt-6`;

const CardCarousel = styled(Slider)`
  ${tw`outline-none`}

  .slick-slide {
    outline: none;
  }
`;

export default ({
  subheading = "Meu espaço",
  headingHtmlComponent = (
    <>
      <span tw="text-primary-500">Eyexpert</span>
    </>
  ),
  description = (<>
    Eyexpert é um consultório oftalmológico pronto para atender você e sua
    família com excelência, preparado para oferecer os melhores diagnósticos e
    tratamentos. Será uma nova experiência de atendimento e cuidado para sua saúde ocular.
    Nosso time está te esperando para uma consulta humanizada, individualizada e amigável,
    associada a uma avaliação cuidadosa. Possui ótima localização próxima ao metrô Paraíso e Ana
    Rosa, e com estrutura física planejada para bem-estar e conforto dos nossos pacientes,
    dedicado a fornecer cuidados especializados para a saúde ocular
    com uma equipe experiente de oftalmologistas e tecnologia avançada
  </>),
  linkText = "Encontre no mapa nosso espaço",
  textOnLeft = false
}) => {
  const cards = [
    {
      images: [
        { imageSrc: salaescritorio }, { imageSrc: salaescritorio2 }
      ],
      company: "Sala de atendimento",
    },
    {
      images: [
        { imageSrc: salaespera }, { imageSrc: salaespera2 }
      ],
      company: "Espaço de espera",
    }
  ];
  return (
    <Container id="espaco">
      <Content>
        <ThreeColumn>
          <HeadingColumn textOnLeft={textOnLeft}>
            <HeadingInfoContainer>
              <Subheading>{subheading}</Subheading>
              <HeadingTitle>{headingHtmlComponent}</HeadingTitle>
              <HeadingDescription>{description}</HeadingDescription>
              <a href="https://goo.gl/maps/2RWcWbbYdjTMrxd68" target="_blank" rel="noreferrer">
                <PrimaryLink>
                  {linkText} <ArrowRightIcon />
                </PrimaryLink>
              </a>
            </HeadingInfoContainer>
          </HeadingColumn>
          {cards.map((card, index) => (
            <CardColumn key={index}>
              <Card>
                <CardCarousel {...sliderSettings}>
                  {card.images.map((image, index) => (
                    <CardImage key={index} imageSrc={image.imageSrc} />
                  ))}
                </CardCarousel>
                <CardText>
                  <CardHeader>
                    <CardCompany>{card.company}</CardCompany>
                    <CardType>{card.type}</CardType>
                  </CardHeader>
                  <CardTitle>{card.title}</CardTitle>
                  <CardMeta>
                    <CardMetaFeature>
                    </CardMetaFeature>
                    <CardMetaFeature>
                    </CardMetaFeature>
                  </CardMeta>
                </CardText>
              </Card>
            </CardColumn>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  );
};