import React from 'react';
import BradescoImage from 'images/Bradesco.webp';
import SulamericaImage from 'images/sulamerica.jpg';
import MediserviseImage from 'images/Mediservice.jpg';
import OmintImage from 'images/omint.png';
import AllianzImage from 'images/allianz.png';
import AmafrespImage from 'images/amafresp.jpg';
import { SectionHeading } from "components/misc/Headings.js";
import tw from "twin.macro";

const Heading = tw(SectionHeading)`w-full text-primary-500 mt-5`;

const Planos = () => {
  const imageStyles = {
    height: '100px',
    display: 'flex',
    margin: '20px',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };

  return (  
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <Heading><span tw="text-primary-500">Alguns dos convênios atendidos</span></Heading>
      <div style={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px' }}>
          <img src={BradescoImage} alt="Bradesco" style={imageStyles} />
          <span style={{ color: 'black', fontSize: '16px', marginTop: '10px' }}>Bradesco</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px' }}>
          <img src={SulamericaImage} alt="Sulamerica" style={imageStyles} />
          <span style={{ color: 'black', fontSize: '16px', marginTop: '10px' }}>Sulamerica</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px' }}>
          <img src={MediserviseImage} alt="Mediservise" style={imageStyles} />
          <span style={{ color: 'black', fontSize: '16px', marginTop: '10px' }}>Mediservice</span>
        </div>
      </div>
      <div style={{ flex: '1', display: 'flex', justifyContent: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px' }}>
          <img src={OmintImage} alt="Omint" style={imageStyles} />
          <span style={{ color: 'black', fontSize: '16px', marginTop: '10px' }}>Omint</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px' }}>
          <img src={AllianzImage} alt="Allianz" style={imageStyles} />
          <span style={{ color: 'black', fontSize: '16px', marginTop: '10px' }}>Allianz</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px' }}>
          <img src={AmafrespImage} alt="Amafresp" style={imageStyles} />
          <span style={{ color: 'black', fontSize: '16px', marginTop: '10px' }}>Amafresp</span>
        </div>
      </div>
    </div>
  );
}

export default Planos;