import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import background from "images/background.jpg";

const Container = styled.div`
  ${tw`relative bg-center bg-no-repeat bg-cover w-full h-screen flex items-center`}
  background-image: url("${background}");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-100 opacity-25`;

const HeroContainer = styled.div`
  ${tw`mt-56 z-20 px-4 max-w-screen-xl mx-auto flex flex-col items-center justify-start h-full`}
  
  @media (min-width: 1024px) {
    margin-right: 50vw;
  }
`;

const AgendamentoDiv = styled.div`
  ${tw`
    w-56 sm:w-72 h-40 sm:h-48 rounded-lg 
    flex items-center justify-center flex-col text-white text-center
    mt-[30vh] sm:mt-[15vh]
  `}
  margin-right: 7vw;
  background-color: rgba(100, 0, 255, 0.5);
`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

export default () => {
  return (
    <Container id="cabecalho">
      <OpacityOverlay />
      <HeroContainer>
        <Heading id="heading">
          <span style={{ fontSize: "0.7em", textShadow: "rgba(0, 0, 0, 0.5) 2px 2px 4px", color: "rgb(100 21 255 / var(--tw-text-opacity))" }}>Dra. Samia Villar Navajas</span>
          <br />
          <span style={{ fontSize: "0.6em", textShadow: "white 1px 1px 2px", color: "rgb(192, 192, 192)" }}>Oftalmologista</span>
        </Heading>
        <AgendamentoDiv id="agendamento">
          <h1
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            Venha conhecer nosso
            <br />espaço
          </h1>
          <a href="https://wa.me/5511966487889?text=Ol%C3%A1%21%21+Gostaria+de+agendar+consulta+com+Dra.+Samia">

            <button
              style={{
                background: "white",
                marginTop: "20px",
                color: "black",
                border: "none",
                borderRadius: "7px",
                padding: "10px 20px",
                fontSize: "0.75rem",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Agende sua consulta!
            </button>
          </a>
        </AgendamentoDiv>
      </HeroContainer>
    </Container>
  );
};
