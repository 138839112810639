import React, { useRef, useEffect } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Cabecalho from "components/hero/BackgroundAsImage.js";
import Especialidades from "components/features/DashedBorderSixFeatures";
import Sobre from "components/features/TwoColSingleFeatureWithStats2.js";
import GoogleMaps from "components/Maps";
import Espaco from "components/cards/PortfolioTwoCardsWithImage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import Whatsapp from "components/Whatsapp";
import LogoImg from "images/logo.png";
import Agendamento from "components/Agendamento";
import Planos from "components/Planos";

// Estilos
const FixedNav = tw.nav`bg-opacity-25 bg-white bg-clip-padding backdrop-blur-sm py-2 fixed w-full z-50 flex justify-between items-center p-6`;
const NavList = tw.ul`flex`;
const NavItem = tw.li`font-medium ml-4 text-gray-700 hover:text-purple-600 transition duration-300 cursor-pointer`;
const ReducedFontSize = tw.span`text-sm`; // Define a reduced font size class

const Logo = tw.img`w-5 cursor-pointer`;
const shadowStyle = {
  textShadow: '2px 2px 4px white', // Adjust the shadow values as needed
};

export default () => {
  const navListRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      const navList = navListRef.current;
      if (navList.scrollWidth > navList.clientWidth) {
        navList.classList.add("reduced-font-size");
      } else {
        navList.classList.remove("reduced-font-size");
      }
    };

    window.addEventListener("resize", checkOverflow);
    checkOverflow();

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      console.log(`Element with id ${id} not found.`);
    }
  };

  return (
    <div>
      <Whatsapp />
      <FixedNav>
        <Logo onClick={() => handleClickScroll("cabecalho")} src={LogoImg} alt="Samia" />
        <NavList ref={navListRef} className="flex">
          <NavItem>
            <ReducedFontSize
              style={shadowStyle}
              onClick={() => handleClickScroll("sobre")}
            >
              SOBRE
            </ReducedFontSize>
          </NavItem>
          <NavItem>
            <ReducedFontSize
              style={shadowStyle}
              onClick={() => handleClickScroll("especialidades")}
            >
              ESPECIALIDADES
            </ReducedFontSize>
          </NavItem>
          <NavItem>
            <ReducedFontSize
              style={shadowStyle}
              onClick={() => handleClickScroll("espaco")}
            >
              ESPAÇO
            </ReducedFontSize>
          </NavItem>
          <NavItem>
            <ReducedFontSize
              style={shadowStyle}
              onClick={() => handleClickScroll("mapa")}
            >
              MAPA
            </ReducedFontSize>
          </NavItem>
        </NavList>
      </FixedNav>
      <Cabecalho />
      <AnimationRevealPage>
        <div>
          <Sobre />
          <Especialidades />
          <Agendamento />
          <Espaco />
        </div>
      </AnimationRevealPage>
      <div>
      </div>
        <GoogleMaps />
      <Planos />
      <Footer />
    </div>
  );
};
